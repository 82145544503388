import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Text from "../components/Text"
import { Section, Columns } from "../components/Containers"

const ThankYouPage = ({ data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <SharedStateProvider>
      <Layout language="en" pageHeading={post.heading}>
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
        />
        <Section>
          <Columns sideColumnsSize={4}>
            <div className="column">
              <Text text={post.body} />
            </div>
          </Columns>
        </Section>
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query thankYouPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        heading
        body
        metaTitle
        metaDescription
      }
    }
  }
`

export default ThankYouPage
